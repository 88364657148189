@import 'src/style/variables';

.normal-button{
    border-radius: 20px;
    background-color: $creame-light-color;
    color: $grey-dark-color;
    border-color: rgba($grey-dark-color, 0.5);
    padding-inline: $default-distance * 1.5;
    transition: background-color 0.2s ease-out, border-color 0.2s ease-out;
    &:active{
        background-color: $active-color;
        color: $creame-light-color
    }
    &:focus{
        background-color: $active-color;
        color: $creame-light-color;
        outline: none;
        box-shadow: none;
    }
    &:hover{
        background-color: $active-color;
        border-color: $active-color;
    }
    
}