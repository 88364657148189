@import 'src/style/variables';
@import 'src/style/helper/helper';

#welcome-screen{
    color: $primary-color-mid;
    /* NOT IN USE RIGHT NOW
    .my-canvas {
        position: absolute;
        width: 100%;
        height: 70%;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        .overlay-canvas{
            position: absolute;
            z-index: 12;
            pointer-events: none;
        }
        .drawing-lines-canvas{
            position: absolute;
            z-index: 11;
            @media only screen and (max-width: 500px){
                display: none;
            }
        }
    }
    */
    .welcome-drawing{
        position: absolute;
        @include maxSize;
        @include flexCenter;
        .drawing{
            width: 50.0%;
            max-height: 60.00%;
            max-width: 600px;
            position: absolute;
            z-index: 0;
        }
        h1{
            font-weight: 700;
            text-align: center;
            @include dynamic-font-size($font-size-big, $font-size-biggest);
            text-shadow: 5px 5px 10px white;
            z-index: 100;
            pointer-events: none;
        }
    }
    .welcome-arrow{
        position: absolute;
        bottom: 7vh;
        height: 50px;
        width: 100%;
        @include flexContentCenter;
        .drawing {
            @keyframes wippin{
                0%{
                    bottom: 0px;
                }
                70%{
                    bottom: 0px;
                }
                80%{
                    bottom: 10px;
                }
                90%{
                    bottom: -3px;
                }
                95%{
                    bottom: 1px;
                }
                100%{
                    bottom: 0px;
                }
            }
            position: absolute;
            bottom: 0px;
            width: 38px;
            z-index: 2;
            animation: wippin 2.5s linear infinite;
        }
        .background {
            position: absolute;
            width: 6px;
            height: 20px;
            background-color: rgba($alternative-color, 0.3);
            z-index: 1;
            border-radius: 2px;
        }
    }
}