@import "src/style/variables";
@import "src/style/helper/helper";

.admin-panel{
  width: 100%;
  @include flexCenterColumn;
  background-color: $background-color;
  input[type="submit"]:disabled {
    transition: all 0.3s ease-in-out;
    cursor: not-allowed;
    background-color: lightgrey !important;
  }
  .slide-in{
  }
  .edit-fenster{
    align-self: stretch;
    display: grid;
    justify-items: center;
    grid-auto-flow: row;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    h2{
      text-align: center;
    }
    overflow: scroll;

    >section, >div {
      max-width: 500px;
      background-color: white;
      padding: $default-distance*2;
      border-radius: 6px;
      box-shadow: 0 4px 30px rgba(167, 165, 165, 0.25);
      margin: $default-distance * 2;
    }
  }
  .marked{
    background-color: rgba(green, 0.1);
  }
  form{
    >*{
      margin: $default-distance 0;
    }
  }
  .auswahl{
    height: 150px;
    list-style: none;
    padding: 0;
    border-style: solid;
    border-width: 1px;
    overflow-y:scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;

    @mixin auswahl{
      width: 100%;
      min-height: 2rem;
      transition: all 0.3s ease-in-out;
      text-align: center;
      cursor: grab;
      padding: 3px;
      margin: 2px;
      &:hover{
        transform: scale(1.2);
        background-color: rgba(grey, 0.1);
      }
    }
    .testimonial-auswahl{
      @include auswahl;
      overflow: hidden;
      max-height: 3rem;
    }
    .member-auswahl {
      @include auswahl;
    }
  }
}