@use 'sass:math';
@import "src/style/variables";
@import "src/style/helper/helper";

#about-you-screen{
    @include flexCenterColumn;
    background-color: $background-color;
    margin-top: $default-distance*8;
    color: $grey-dark-color;
    .aboutyou-icon{
        margin-bottom: $default-distance;
    }
    .h1{
        text-align: center;
        width: 100%;
        padding-left: $default-distance;
        padding-right: $default-distance;
    }
    .h2{
        font-weight: 200;
    }
    .vs{
        margin-top: $default-distance*6;
        margin-bottom: $default-distance*6;
        .vs-toggle{
            @include flexItemsCenter;
            >*{
                margin: $default-distance;
                @media only screen and (max-width: 575px){
                    margin: math.div($default-distance, 2.5);
                }
            }
            p{
                @include dynamic-font-size($font-size-small*0.66, $font-size-normal);
                color: rgba($grey-dark-color,0.5);
            }
        }
    }
    .perfect-match{
        height: 500px;
        @include flexCenterColumn;
        text-align: center;
        >*{
            margin: $default-distance * 0.5;
        }
        .icon{
            @keyframes iconScales {
                0% {
                    transform: scale(1.0);
                }
                33% {
                    transform: scale(2.5);
                }
                66% {
                    transform: scale(1.0);
                }
                100% {
                    transform: scale(1.5);
                }
            }
            transform: scale(1.5);
            animation: iconScales 750ms linear 250ms forwards;
            
        }
        .title{
            @include dynamic-font-size($font-size-normal, $font-size-big);
            font-weight: 500;
        }
        .text{
            @include dynamic-font-size($font-size-small, $font-size-normal);
            line-height: 150%;
        }
    }

}