@import "src/style/variables";
@import "src/style/helper/helper";

@mixin basicContent {
    background-color: $background-color;
    position: relative;
    @include flexCenterColumn;
    margin-top: $default-distance * 8;
    margin-bottom: $default-distance * 8;
    .linie {
        width: 100%;
        border-bottom: 1px solid rgba($grey-dark-color, 0.2);
        margin-bottom: 10px;
    }
    button {
        margin: $default-distance;
    }
    * {
        text-align: center;
    }
    small {
        display: block;
    }
    .topics-elements {
        overflow: scroll;
        width: fit-content;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 100%;
        @keyframes ausfahren {
            from {
                max-height: 0px;
            }
            to {
                max-height: 500px;
            }
        }
        animation: ausfahren 1s linear forwards;
        .normal-button {
            overflow: hidden;
            transform: scale(0.8);
            margin: 0;
            opacity: 1;
            min-width: 200px;
        }

    }
}

@mixin similatirySetting($box-size) {
    overflow: hidden;
    #image-from-user {
        border-radius: 100px;
        @include maxSize;
    }
        @include basicContent();

    .bilder-container {
        position: relative;
        height: fit-content;
        max-width: 100%;
        padding: $default-distance * 2;
        margin: $default-distance * 2;
        display: grid;
        grid-template-columns: repeat(14, 1fr);
        grid-template-rows: repeat(7, 1fr);
        align-items: center;
        justify-items: center;
        gap: 5px;
        > div {
            width: $box-size;
        }
        .spot {
            position: relative;
            height: $box-size;
            width: $box-size;
            .member-image {
                position: relative;
                height: $box-size;
                border-radius: $box-size;
                background-size: cover;
            }
            .hover-text {
                position: absolute;
                visibility: hidden;
                width: 200px;
                text-align: center;
                transform: translateX(-65px);
                background-color: $background-color;
                border-radius: 6px;
                z-index: 10;
                padding: 2px;
            }
            .member-image {
                transition: 0.3s ease-in-out;
            }
            &:hover {
                > .member-image {
                    transform: scale(1.5);
                }
                .hover-text {
                    visibility: visible;
                }
            }
        }
        .add-image {
            @include centerMeOnYX;
            background-image: url("./svgs/plus.svg");
            background-repeat: no-repeat;
            background-position: center;
            height: 100px;
            width: 100px;
            background-color: rgba($grey-dark-color, 0.05);
            border-radius: 100px;
            transition: 0.3s ease-in-out;
            &:hover {
                background-color: rgba($grey-dark-color, 0.1);
                transform: translate(-50%, -50%) scale(1.05);
            }
            .text {
                text-align: center;
                @include centerMeOnX;
                top: 110%;
                width: 200px;
                background-color: rgba($background-color, 0.5);
                border-radius: 6px;
            }
        }
    }
}

#similatiry-screen {
    $box-size: 70px;
    $showcase-width: 14 * $box-size;
    $showcase-width-with-maxoffset: 14 * $box-size + 30px + 70px;

    @media only screen and (min-width: $showcase-width-with-maxoffset) {
        @include similatirySetting($box-size);
    }
    @media only screen and (max-width: $showcase-width-with-maxoffset) {
        @include basicContent();
        .bilder-container {
            margin-top: $default-distance * 2;
            margin-bottom: $default-distance * 6;
            display: grid;
            align-items: center;
            justify-items: center;
            width: 100%;
            grid-template-columns: repeat(2, 1fr);
            @media only screen and (max-width: 565px) {
                grid-template-columns: auto;
            }
            div {
                position: absolute;
            }
            .spot {
                position: relative;
                margin: $default-distance;
                padding: $default-distance;
                background-color: $creame-light-color;
                box-shadow: 0px 4px 30px rgba(167, 165, 165, 0.25);
                border-radius: 6px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                width: 90%;
                height: 90%;
                > * {
                    margin: $default-distance;
                }
                .member-image {
                    position: relative;
                    height: $box-size*2;
                    width: $box-size*2;
                    border-radius: $box-size*2;
                    background-size: cover;
                    left: 0 !important;
                    top: 0 !important;
                }
                .hover-text {
                    position: relative;
                    left: 0 !important;
                    top: 0 !important;
                }
            }
            .add-image {
                visibility: hidden;
            }
        }
    }
}
