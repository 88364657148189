@import "src/style/variables";
@import "src/style/helper/helper";
#customer-screen {
    position: relative;
    margin-bottom: $default-distance*2;
    display: grid;
    grid-template-columns: 1fr 1.1fr;
    justify-items: left;
    align-items: center;
    gap: calc(100px + 5vw);
    @media only screen and (max-width: 1200px) {
        gap: 40px;
    }

    .h1{
        position: relative;
        max-width: 300px;
        text-align: left;
        justify-self: right;
    }

    .cards{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .customer-card{
            margin: $default-distance;
            height: fit-content;
            @include flexCenter;
            width: 90%;
            border-radius: 6px;
            box-shadow: 0px 4px 30px rgba(167, 165, 165, 0.25);
            background-color: $creame-light-color;
            padding: $default-distance*2;
            img {
                max-width: 100%;
            }
        }
    }
    @media only screen and (max-width: 762px){
        grid-template-columns: auto;
        .h1{
            width: 100%;
            text-align: center;
            justify-self: center;
        }
        .cards{
            align-items: center;
            justify-content: flex-start;
            .cards-container {
                @include flexCenterColumn;
            }
            .customer-card {
                width: 95%;
                img {
                    height: auto;
                }
            }
        }
    }
    @media only screen and (min-width: 1200px){

        .cards{
            display: flex;

            .cards-container{
                position: relative;
                height: 700px;
                width: 700px;
                >*{
                    @include flexCenter;
                }
                #customer-card-1{
                    position: absolute;
                    top: 8%;
                    left: 150px;
                    width: 200px;
                }
                #customer-card-2{
                    position: absolute;
                    top: 40%;
                    left: 250px;
                    width: 360px;
                }
                #customer-card-3{
                    position: absolute;
                    top: 72%;
                    width: 400px;
                }
                .customer-card{
                    margin:0;
                    width: 200px;
                    height: 200px;
                }
            }
            
        }
    }
}