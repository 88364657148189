@use 'sass:math';
@import 'src/style/variables';


.big-button-container{
    width: math.div(393px, 1.33);
    height: math.div(112px, 1.33);
    border-radius: 10px;
    @include dynamic-font-size($font-size-small, $font-size-normal);
    box-shadow: 0 4px 20px rgba(167, 165, 165, 0.25);
    background-color: white;
    position: relative;
    border: none white;
    transition: border-color 0.3s ease-out, background-color 0.2s ease-out;
    .bigbuttontext{
        position:absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
        pointer-events: none;
    }
    .big-button-radio{
        position: relative;
        appearance: none;
        width: 100%;
        height: 100%;
    }
    input:hover {
        cursor: pointer;
    }
    &:hover{
        border-color: $primary-color-mid;
        background: none;
        border-style: solid;
        border-width: 3px;
    }
    &:focus {
        outline: none;
        box-shadow: none;
        border-style: none;
    }
    label{
        z-index: 1;
    }
    @media only screen and (max-width: 767px) {
        width: math.div(393px, 2);
        height: math.div(112px, 2);
    }
    @media only screen and (max-width: 575px) {
        width: math.div(393px, 2.5);
        height: math.div(112px, 1.5);
    }
}
