@import 'src/style/variables';

.cta-panel{
    position: relative;
    height: fit-content;
    margin-inline: $default-distance*4;
    margin-block: $default-distance*4;
    background-color: $primary-color-mid;
    border-radius: 6px;
    color: $creame-light-color;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    align-items: center;
    justify-items: center;
    background-image: url('./svgs/wave.svg');
    background-repeat: repeat-x;
    background-size: cover;
    @media only screen and (max-width: 767px) {
        text-align: center;
    }
    >*{
        margin: 6.25vw;
    }
    .title{
        .h2{
            font-weight: normal;
            display: block;
        }
        .h3 {
            font-weight: 300;
            display: block;
        }
    }
    .cta-button{
        justify-self: center;
    }
    @media only screen and (max-width: 767px){
        grid-template-columns: auto;
    }
    @media only screen and (max-width: 567px){
        grid-template-columns: auto;
        .cta-button{
            justify-self: center;
            width: 90%;
        }
    }
}