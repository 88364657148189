@import "~bootstrap/scss/bootstrap";
@import 'src/style/variables';
@import 'src/style/helper/helper';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700;800&display=swap');

html, body{
    background-color: $background-color !important;
}

.screen {
    //global screen settings
    min-height: 100vh; //fallback
    min-height: vh(100);
    overflow: hidden;
}

h1, .h1{
    //globale font size für überschriften
    @include dynamic-font-size($font-size-big, $font-size-bigger);
}

h2, .h2{
    //globale font size für subüberschriften
    @include dynamic-font-size($font-size-normal, $font-size-normal*1.66);
    font-weight: 200;
}

h3, .h3 {
    //globale font size für subsubüberschriften
    @include dynamic-font-size($font-size-normal, $font-size-normal*1.2);
}