@import 'src/style/variables';

#footer-panel{
    height: 400px;
    background-color: $primary-color-mid;
    color: $creame-light-color;
    background-image: url('../CTAPanel/svgs/wave.svg');
    background-position: center top 100px;
    background-repeat: repeat-x;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    .peace-icon{
        position: absolute;
        left: 10%;
        @media only screen and (max-width: 1300px){
            top: 19%;
            left: 50%;
            transform: translateX(-60%);
        }
        @media only screen and (max-width: 730px){
            top: 15%;
            left: 50%;
            transform: translateX(-60%) scale(0.8);
        }
    }
    >*{
        margin-left: 10px;
        margin-right: 10px;
    }
    
    .logo {
        transform: translateY(-80px);
    }
    h2{
        margin-bottom: $default-distance*3;
        font-weight: normal;
        text-align: center;
    }
}