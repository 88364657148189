@import 'src/style/variables';

.cta-button{
    border-radius: 100px;
    width: 273px;
    height: fit-content;
    padding: $default-distance*0.5 $default-distance $default-distance*0.5 $default-distance;
    background-color: $primary-dark;
    color: $creame-light-color;
    font-size: 1.3rem;
    border: none;
}