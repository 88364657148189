@import "src/style/variables";

#header-navigation {
    display: grid;
    grid-template-columns: 1fr 1.6fr;
    align-items: center;
    justify-items: center;
    width: 100%;
    overflow: hidden;
    padding: 0;
    a {
        @include dynamic-font-size($font-size-small, $font-size-normal);
        padding-inline: $default-distance * 3;
        @media only screen and (max-width: 950px){
            padding-inline: $default-distance * 2;
        }
        padding-top: 35px;
        padding-bottom: 35px;
        color: $grey-dark-color;
    }

    .logo {
        @media only screen and (max-width: 767px) {
            margin-top: auto;
        }
    }

    @media only screen and (max-width: 767px){
        .navcollapse {
            justify-self: right;
            margin-right: $default-distance*3;
        }

        .logo-container {
            justify-self: left;
            a {
                margin: 0px;
                padding: 0px;
            }
            margin-left: $default-distance*3;
        }
    }


    .navbar-toggler{
        border: none;
        box-shadow: none;
        position: relative;
        width: 30px;
        height: 30px;
        @media only screen and (min-width: 767px) {
            visibility: hidden;
            display: none;
        }

        .navbar-toolbar {
            display: inline;



            span {
                position: absolute;
                width: 30px;
                height: 4px;
                border-radius: 3px;
                background-color: $grey-dark-color;

                transition: all 216ms ease-in-out;
            }
        }

        &.collapsed {
            span {
                &:nth-child(1) {
                    top: 2px;
                    left: 0;
                    transform: rotate(0deg);
                }

                &:nth-child(2) {
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    opacity: 1;
                }

                &:nth-child(3) {
                    bottom: 2px;
                    left: 0;
                    transform: rotate(0deg);
                }
            }
        }
        span {
            &:nth-child(1) {
                top: 50%;
                left: 0;
                transform: translateY(-50%) rotate(45deg);
            }

            &:nth-child(2) {
                top: 50%;
                transform: translateY(-50%);
                opacity: 0;
                left: 0;
            }

            &:nth-child(3) {
                bottom: 50%;
                left: 0;
                transform: translateY(+50%) rotate(-45deg);
            }
        }
    }

    .navigation {
        position: relative;
        transition: all 0.2s ease-out;
        &:hover{
            color: rgba($grey-dark-color, 0.5);
        }
        .stroke {
            position: absolute;
            height: 25%;
            background-color: rgba($grey-dark-color, 0.5);
            width: 2px;
            transform: translateX(-$default-distance);
            @media only screen and (max-width: 767px) {
                display: none;
            }
        }
    }

    .auswahl {
        .space {
            height: 0;
            display: none;
        }
        @media only screen and (max-width: 767px) {
            background-color: rgba($background-color, 1);
            border-radius: 0;
            position: fixed;
            left: 35%;
            width: 100%;
            top: $default-distance*5;
            * {
                font-size: $font-size-normal-px;
            }
            .space {
                height: 100vh;
                display: block;
                pointer-events: none;
            }
        }
    }

    @media only screen and (max-width: 767px) {
        grid-template-columns: 1fr 1fr;
    }
}
