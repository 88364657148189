@import 'src/style/variables';

#testimonial-screen{
    $padding: 40px;
    $margin: 0px;
    $border: 0px;
    $elementwidth: 260px;

    $max-size: $elementwidth * 3 + $padding * 2 + $margin * 6 + $border * 2;
    $mid-size: $elementwidth * 2 + $padding * 2  + $margin * 4 + $border * 2;
    $small-size: $elementwidth * 1 + $padding * 2  + $margin * 2 + $border * 2;

    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    h3, .h3{
        text-align: center;
    }

    .testimonial-slider{
        *{
            user-select: none;
            touch-action: pan-y;
        }
        max-width: 100%;
        .balle-container {
            position: absolute;
            pointer-events: none;
            width: 100%;
            background-image: url('./svgs/background.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            @media only screen and (max-width: $max-size) {
                width: 100vw;
            }
            height: 100%;
            .small-ball-dark{
                position: absolute;
                transform: scale(0.5);
                left: 20%;
            }
            .mid-ball-dark{
                position: absolute;
                left: 22%;
                top: 5%;
            }
            .small-ball-light {
                position: absolute;
                bottom: 0;
                right: 25%;
                transform: scale(0.3);
            }
            .mid-ball-light {
                position: absolute;
                bottom: 7%;
                right: 25.5%;
                transform: scale(0.45);
            }
            .big-ball-light {
                position: absolute;
                bottom: 8%;
                right: 29%;
                transform: scale(0.8);
            }
        }

        height: 300px * 1.3 + 150px;
        @media only screen and (max-width: 640px){
            width: 90%;
        }
        margin: $default-distance*4;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .slider-elements {

            border: solid $border $creame-light-color;

            padding: $padding;

            >* {
                margin: $margin;
            }
            width: $max-size;

            --anzahl-sichtbarer-elemente: 3;
            @media only screen and (max-width: $max-size) {
                width: $mid-size;
                --anzahl-sichtbarer-elemente: 2;
            }

            @media only screen and (max-width: $mid-size + $default-distance * 4) {
                width: $small-size;
                padding: 0px;
                --anzahl-sichtbarer-elemente: 1;
            }

            display: flex;
            overflow: hidden;

            .slider-element-container{
                min-width: $elementwidth;
                @media only screen and (max-width: $mid-size) {
                    min-width: $elementwidth;
                }
                @media only screen and (max-width: $mid-size + $default-distance * 4) {
                    min-width: 100%;
                }
                .slider-element{
                    position: relative;

                    border-bottom: 10px solid;
                    border-image: linear-gradient(to right, $background-color, $creame-light-color);
                    border-image-slice: 1;
                    overflow: scroll;
                    background-color: $creame-light-color;
                    box-shadow: 0px 4px 30px #EFE8E4;
                    height: 320px;

                    -ms-overflow-style: none;  /* IE and Edge */
                    scrollbar-width: none;  /* Firefox */
                    &::-webkit-scrollbar {
                        display: none;
                    }

                    text-align: center;
                    margin: 20px;

                    display: grid;
                    grid-template-rows: 1fr 1fr;
                    justify-content: center;
                    align-items: center;
                    justify-items: center;
                    .slider-element-header{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: flex-end;
                        height: 100%;
                        .by {
                            font-weight: bold;
                            font-size: $font-size-small-px;
                            margin: 20px;
                        }
                        .image {
                            width: 60px;
                            height: 60px;
                            border-radius: 50px;
                            background-position: center;
                            background-size: cover;
                        }
                    }
                    .slider-element-body{
                        margin: 20px;
                        overflow: auto;
                        align-self: baseline;
                        .text {
                            font-size: 20px;
                            font-weight: 300;
                            margin-bottom: $default-distance*2;
                        }
                    }


                }
            }

        }

        @mixin arrow {
            width: 33px * 0.5;
            height: 49px * 0.5;
            z-index: 2;
            transition: width 333ms ease-in-out, height 333ms ease-in-out, transform 100ms ease-in-out;
            outline: none;
            border: none;
            background-color: rgba(0,0,0,0);
            &:hover {
                width: 33px * 0.6;
                height: 49px * 0.6;
            }
            &:active {
                transform: rotate3d(0, 1, 0, 45deg);
            }
        }
        .arrow-left{
            position: absolute;
            left: 0;
            background-image: url('./svgs/arrow-left.svg');
            background-repeat: no-repeat;
            background-size: contain;
            @include arrow
        }
        .arrow-right{
            position: absolute;
            right: 0;
            background-image: url('./svgs/arrow-right.svg');
            background-repeat: no-repeat;
            background-size: contain;
            animation: makearrowbigger 100ms linear forwards;
            @include arrow
        }
    }

}
