@use "sass:map";
@import "~bootstrap/scss/bootstrap";
@import 'src/style/variables';
@import 'src/style/helper/helper';

#our-values-screen{
    @include coveredBackground('/Screens/_SpecialBackground/img/background.png');
    background-color: $primary-color-mid;
    color: $creame-light-color;
    position: relative;

    .our-values-screen-header{
        margin-top: $default-distance*8;
        @include flexCenterColumn;

        .icon {
            margin-bottom: $default-distance;
        }

        .h2 {
            font-weight: 200;
        }
    }
    .listing{
        position: relative;
        padding-inline: 3%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: $default-distance*6;
        margin-bottom: $default-distance*6;
        @media only screen and (max-width: map.get($grid-breakpoints, "sm")) {
            grid-template-columns: auto;

        }

        li{
            position: relative;
            font-size: $font-size-normal-px;
            font-weight: 200;
            @media only screen and (min-width: map.get($grid-breakpoints, "lg")){
                font-size: $font-size-big-px;
            }
            margin-left: 22.50%;
            margin-block: $default-distance;
            @media only screen and (max-width: map.get($grid-breakpoints, "sm")){
                margin-inline: $default-distance*2;
                align-self: center;
                justify-self: center;
            }
            list-style-type: none;
            padding-left: 15px;
            @media only screen and (min-width: map.get($grid-breakpoints, "lg")){
                padding-left: 25px;
            }
            &::before {
                position: absolute;
                content: '';
                @mixin sizing($s, $f){
                    width: #{$s}+px;
                    height: #{$s}+px;
                    left: 0;
                    top: #{$f + $s * 0.5} + px;
                }
                $halffontnormal: $font-size-normal * 0.5;
                $halffontbig: $font-size-big * 0.5;
                $size: 10;
                @media only screen and (min-width: map.get($grid-breakpoints, "lg")){
                    @include sizing($size, $halffontbig - 1);
                }
                @include sizing($size * 0.5, $halffontnormal - 1);


                border-radius: 50%;
                background-color: white;
            }
        }
    }

}