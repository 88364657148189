//--------COLORS--------------
$primary-light: #5182E3;
$primary-dark: #2C59B1;
$primary-color-mid: #4374D5;
$active-color: #61A6F6;
$background-color: rgb(252,250,249);
$alternative-color: #8F38FE;
$grey-dark-color: #262627;
$creame-light-color: #FCFAF9;

//--------MARGIN/PADDING--------
$default-distance: 15px;

//--------FONTS-----------------
$font-size-small: 12;
$font-size-normal: 18;
$font-size-big: 32;
$font-size-bigger: 48;
$font-size-biggest: 66;

$font-size-small-px: $font-size-small+px;
$font-size-normal-px: $font-size-normal+px;
$font-size-big-px: $font-size-big+px;
$font-size-bigger-px: $font-size-bigger+px;
$font-size-biggest-px: $font-size-biggest+px;

/**
  Erstellt Textgröße dynamisch zwischen $fontsize und $fontsizemax
  Achtung werte OHNE px übergeben
 */
@mixin dynamic-font-size($fontsize, $fontsizemax){
  font-size: #{$fontsize}px;
  @media only screen and (min-width: 480px) {
    font-size: calc(#{$fontsize}px + (#{$fontsizemax} - #{$fontsize}) * ((100vw - 480px) / (1600 - 480)));
  }
  @media only screen and (min-width: 1600px){
    font-size: #{$fontsizemax}px;
  }
}
