@function vh($quantity) {
    @return calc(var(--vh, 1vh) * #{$quantity});
}

@mixin interfont {
    font-family: 'Inter', sans-serif;
}

@mixin maxSize {
    height: 100%;
    width: 100%;
}

@mixin flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flexCenterColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@mixin flexContentCenter {
    display: flex;
    justify-content: center;
}

@mixin flexItemsCenter {
    display: flex;
    align-items: center;
}

@mixin coveredBackground($url) {
    background-image: url($url);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

@mixin centerMeOnY {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

@mixin centerMeOnX {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

@mixin centerMeOnYX {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}