@import "src/style/variables";

#header-panel {
    z-index: 1000;
    position: fixed;
    height: fit-content;
    @media only screen and (max-width: 767px) {
        height: $default-distance*5;
    }


    width: 100%;
    background-color: rgba($creame-light-color, 1);

    color: $grey-dark-color;
    .logo {
        height: 40%;
    }

    @media only screen and (max-width: 767px) {
        padding: $default-distance 0;
    }
}
