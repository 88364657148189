.EncodeLogin {
    form{
        margin: 20px;
        display: grid;
        place-items: center;
        input {
            margin: 10px;
            width: 80%;
        }
    }
}
