@import "src/style/variables";
@import "src/style/helper/helper";

#discover-screen{
    @include coveredBackground('/Screens/_SpecialBackground/img/background.png');
    $padding: $default-distance*8;
    background-color: $primary-color-mid;
    color: $creame-light-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    position: relative;
    @media only screen and (max-height: 650px) {
        min-height: 650px;
    }

    >*{
        z-index: 1;
    }
    .head{
        text-align: center;
        padding-top: $padding;
        .h2{
            font-weight: 200;
        }
    }
    .transp{
        opacity: 0;
    }
    .du-und-encode{
        max-height: 100%;
        @include centerMeOnY;
        height: fit-content;
        width: 100%;
        @include flexCenter;
        background: none;
        z-index: 0;
        @mixin blobSetting(){
            position: relative;
            width: 20%;
            min-width: 300px;
        }
        .du-blob{
            @include blobSetting;

            transform: translateX(17%);
        }
        .animate-du {
            @keyframes duBlobSlidesIn {
                from {
                    transform: translateX(-100%) scale(2);
                    opacity: 0;
                } to {
                      transform: translateX(17%) scale(1.2);
                      opacity: 1;
                  }
            }
            animation: duBlobSlidesIn 1.3s ease-out forwards;
        }
        .animate-encode {
            @keyframes encodeBlobSlidesIn {
                from {
                    transform: translateX(100%) scale(2);
                    opacity: 0;
                } to {
                      transform: translateX(-17%) scale(1);
                      opacity: 1;
                  }
            }
            animation: encodeBlobSlidesIn 1.3s ease-out forwards;
        }
        .animate-text {
            @keyframes textSlowlyAppears {
                from {
                    opacity: 0;
                } to {
                      opacity: 1;
                  }
            }
            animation: textSlowlyAppears 1.3s linear forwards;
            animation-delay: 700ms;
        }
        .encode-blob{
            @include blobSetting;

            transform: translateX(-17%);
        }
        .text{
            $size: 50px;
            align-self: center;
            height: $size;
            opacity: 0;
            h1{
                height: $size;
                display: flex;
                justify-content: center;
                align-items: baseline;
            }
            .du-text{
                position: absolute;
                transform: translateX(-100px);
                @media only screen and (min-width: 1000px) {
                    transform: translateX(-140px);
                }
                @media only screen and (min-width: 2400px) {
                    transform: translateX(-150px);
                }
            }
            .und-text{
                @include centerMeOnX;
            }
            .encode-text{
                transform: translateX(40px);
                position: absolute;
                @media only screen and (min-width: 1000px) {
                    transform: translateX(60px);
                }
                @media only screen and (min-width: 2400px) {
                    transform: translateX(70px);
                }
            }
        }
        
    }
    
}